import React from 'react';
import titleImg from '../images/title-page-img.jpg';
export default function AboutMassage() {
  return (
    <section className="flex justify-center items-center flex-row flex-wrap mt-8 tablet:pt-12 pb-32">
      <div className="px-4 py-2 tablet:py-8 rounded-3xl tablet:ml-12 mobile:max-w-xl tablet:max-w-full laptop:max-w-screen-xl tablet:px-24 laptop:px-8 tablet:bg-white/80 tablet:shadow-2xl">
        <div className="flex flex-col items-center justify-between w-full mb-10 laptop:flex-row">
          <div className="mb-16 laptop:mb-0 laptop:max-w-2xl laptop:pr-5">
            <div className="max-w-xl tablet:my-6">
              <h2 className="font-title text-5xl text-center tablet:text-start tablet:mt-0 my-6 font-thin tracking-tight text-charcoal tablet:text-8xl tablet:leading-none max-w-lg">
                Appointments:
              </h2>
              <p className="text-charcoal laptop:text-lg py-8 text-start px-8 tablet:px-2 tablet:text-start">
                Massages include the use of heat and essential oils (unless
                otherwise requested); and she incorporates Circulatory and
                Sports massage, Trigger Point Therapy and relaxation techniques.
              </p>
              <p className="text-charcoal laptop:text-lg text-start px-8 tablet:px-2 tablet:text-start">
                Appointments consist of a brief consultation and a one, or one
                and a half hour massage session. Every session is customized,
                and tailored to her clients needs and issues.
              </p>
            </div>
          </div>
          <img
            alt="logo"
            width="325"
            height="120"
            src={titleImg}
            className="object-cover rounded-2xl border
          border-solid border-4 border-rock"
          />
        </div>
      </div>
    </section>
  );
}
