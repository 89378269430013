export const navLinks = [
  {
    index: 0,
    id: '/',
    title: 'Home',
  },
  {
    index: 1,
    id: '/aboutdeeptissue',
    title: 'Deep Tissue and Sports Massage',
  },
  {
    index: 2,
    id: '/aboutmigraines',
    title: 'Migraine headaches',
  },
  {
    index: 3,
    id: '/contact',
    title: 'Contact',
  },
  {
    index: 4,
    id: '/testimonials',
    title: 'Testimonials',
  },
];

export const massageBenefits = [
  {
    id: 1,
    description: 'Relieves muscle tightness and spasms',
  },
  {
    id: 2,
    description: 'Relieves Stress',
  },
  {
    id: 3,
    description: 'Relieves Pain',
  },
  {
    id: 4,
    description: 'Relieves Migraine and Tension Headaches',
  },
  {
    id: 5,
    description: 'Detoxifies the Body and Muscles',
  },
  {
    id: 6,
    description: 'Strengthens the Immune System',
  },
  {
    id: 7,
    description: 'Accelerates Healing and Recovery',
  },
  {
    id: 8,
    description: 'Aids in Digestion',
  },
  {
    id: 9,
    description: 'Stimulates Metabolism',
  },
  {
    id: 10,
    description: 'Improves Circulation',
  },
  {
    id: 11,
    description: 'Improves Nerve Function',
  },
  {
    id: 12,
    description: 'Improves Quality of Sleep',
  },
  {
    id: 13,
    description: 'Improves Posture',
  },
  {
    id: 14,
    description: 'Improves Flexibility and Range of Motion',
  },
  {
    id: 15,
    description: 'Compliments Physical Fitness Regimens',
  },
  {
    id: 16,
    description: 'Improves Athletic Performance',
  },
  {
    id: 17,
    description: 'Lowers Blood Pressure',
  },
  {
    id: 18,
    description: 'Enhances Post-Operative Rehabilitation',
  },
  {
    id: 19,
    description: 'Improves Rehabilitation After Injury',
  },
  {
    id: 20,
    description: 'Inspires Strength in the Body, and Clarity in the Mind',
  },
];
