import React from 'react';

export default function Footer() {
  return (
    <div className="w-full bg-white/40 h-32 flex flex-row-reverse items-end pb-8 px-8">
      <p className="text-charcoal font-sans font-thin text-medium">
        Made with love by Mira Kinebuchi ©
      </p>
    </div>
  );
}
