import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './views/Home';
import Contact from './views/Contact';
import Testimonials from './views/Testimonials';
import NavBar from './components/NavBar';
import Footer from './views/Footer';
import DeepTissue from './views/DeepTissue';
import Migraines from './views/Migraines';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aboutdeeptissue" element={<DeepTissue />} />
        <Route path="/aboutmigraines" element={<Migraines />} />
        <Route path="/testimonials" element={<Testimonials />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
