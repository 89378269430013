import React from 'react';
import { massageBenefits } from '../data';
import deeptissue from '../images/deeptissue.jpg';

export default function DeepTissue() {
  return (
    <div className="flex flex-col tablet:px-12 bg-rock">
      <div className="py-4 rounded-xl">
        <div className="py-8 text-center">
          <div className="text-5xl tablet:text-7xl text-charcoal font-title">
            Deep Tissue and Sports Massage
          </div>
          <div className="lead-xl font-light text-2xl mt-8 font-sans">
            Information about Deep Tissue and Sports Massage
          </div>
        </div>
      </div>
      <div className="bg-white/40 rounded-xl tablet:py-12 flex flex-col tablet:px-10 tablet:flex-row items-center justify-center gap-6">
        <div>
          <img
            src={deeptissue}
            alt="relaxing beach"
            className="rounded-t-xl w-full"
          />
          <div className="px-6 pt-10 pb-14 bg-pink rounded-lg shadow-2xl flex flex-col items-center">
            <div className="text-white space-y-4">
              <div className="text-lg font-light flex flex-col items-center px-2">
                <p className="mt-4 mb-12 text-center text-xl">
                  Deep Tissue and Sports Massage are powerful and very effective
                  techniques for releasing muscle tightness and trauma. These
                  techniques target the deeper layers of the muscles and
                  connective tissues to relieve tension, release toxins,
                  stimulate circulation, and promote health!
                </p>
                <h2 className="w-full tablet:w-5/6 text-3xl font-title text-center py-6 font-bold text-charcoal bg-white/40 rounded-xl shadow-lg">
                  What is a "knot"?
                </h2>
                <p className="my-4 text-lg laptop:mx-10 font-sans">
                  As muscles are used, toxins and metabolic waste are released
                  to the surface, and build up between the fibers of the muscle.
                  This build-up will cause muscle fibers to adhere to one
                  another and contract in certain points. This tightness feels
                  like lumps, or ropes, or "knots" when rubbed, and can cause
                  pain, headaches, muscle spasms, numbness, decreased range of
                  motion, and many other symptoms depending on the muscle, and
                  where the adhesion's are. What deep tissue massage aims to do
                  is to detoxify the muscle through releasing the build-up of
                  toxins and waste from between the fibers. This is done by
                  using slow, firm pressure with your therapist's hands, thumbs,
                  elbows, forearms, knuckles, and fingertips. These intense
                  strokes will strip out the build-up of toxins, release the
                  tightness, and improve circulation to the muscle.
                </p>
                <h2 className="w-full tablet:w-5/6 text-3xl font-title text-center py-6 font-bold text-charcoal bg-white/40 rounded-xl shadow-lg px-2">
                  What is "muscle soreness"?
                </h2>
                <p className="my-4 text-lg laptop:mx-10 font-sans">
                  Muscle soreness is a result usually felt after strenuous use
                  or exercise. When muscles are exerted, very small, microscopic
                  tears are created in the muscle fibers and tissues. These
                  tears are very common and not damaging, however they can cause
                  inflammation, swelling, tenderness and pain. Circulatory or
                  "sports" massage can aid in, and accelerate recovery by
                  stimulating circulation and blood flow to the areas of
                  soreness. The blood carries with it oxygen and essential
                  nutrients that muscles and tissues require to heal. This
                  technique of massage is done by using light to deep pressure
                  in a circular or kneading motion to the muscles and
                  surrounding areas. By generating blood and circulation to
                  muscles which have been overextended, it will promote a
                  quicker and more efficient recovery, relieve pain and
                  soreness, and minimize scar tissue. This therapy can also be
                  used for a variety of injuries, physical ailments and
                  conditions, post operative recovery and stress.
                </p>
                <h2 className="w-full tablet:w-10/12 text-2xl font-title text-center py-6 font-bold text-charcoal bg-white/40 rounded-xl shadow-lg px-4">
                  There are countless benefits to receiving Massage Therapy on a
                  regular basis:
                </h2>
                <div className="flex items-start pl-4 tablet:px-20 w-full">
                  <div className="flex items-start pt-8">
                    <ul className="flex flex-col gap-y-2.5 laptop:ml-20">
                      {massageBenefits.map((massage) => (
                        <li
                          className="flex space-x-3 text-white font-sans"
                          key={massage.id}
                        >
                          <img
                            width="50"
                            height="50"
                            src="https://img.icons8.com/ios-filled/50/FFFFFF/checked--v1.png"
                            alt="checked--v1"
                            className="w-6 h-6"
                          />
                          <span className="paragraph-l font-bold">
                            {massage.description}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p className="my-4 pt-8 text-lg text-white laptop:mx-10 font-sans">
              Shauna Moody has been practicing massage for over 20 years and has
              seen the benefits realized. She has been able to help people out
              of pain, and off medication... what a gift!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
