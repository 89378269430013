import React from 'react';
import { Link } from 'react-router-dom';
import shaunaProfile from '../images/profile-pic.png';

export default function TitlePage() {
  return (
    <>
      <div className="h-3/4 bg-white flex items-start border border-solid border-violet">
        <section className="py-12 tablet:py-24 w-full flex justify-center flex-col tablet:flex-row ">
          <div className="flex flex-col flex-wrap justify-center items-center px-4 tablet:justify-start tablet:items-start tablet:ml-24">
            <h1 className="text-5xl text-center tablet:text-start tablet:text-8xl font-thin mb-6 text-charcoal font-title">
              Shauna Michelle Moody LMT, NCBTMB
            </h1>
            <div className="container text-left text-charcoal flex justify-center">
              <div className="flex flex-col tablet:flex-row items-center w-10/12 z-20">
                <div className="min-w-[250px] w-1/2 tablet:w-full rounded-xl p-4 my-4 flex items-center z-30 tablet:hidden bg-rock/40 shadow-xl">
                  <img
                    src={shaunaProfile}
                    className="h-52 tablet:h-full w-full object-cover rounded-2xl border border-solid border-4 border-rock"
                    alt="massage therapist profile"
                  />
                </div>
              </div>
            </div>
            <div className="w-5/6 z-10 py-4 flex flex-col items-center justify-center tablet:items-start tablet:justify-start">
              <p className="text-xl mb-12 text-charcoal">
                Shauna Moody graduated as a Certified Massage Practitioner from
                The School Of Healing Arts in San Diego, California in 2005, and
                has been practicing therapeutic massage ever since. She
                specializes in Deep Tissue Massage for the treatment of migraine
                headaches, muscle tightness and spasms, neck, shoulder and back;
                stress, pain and injury.
              </p>
              <Link
                to="/aboutdeeptissue"
                className="bg-indigo-500 text-white py-4 px-12 rounded-full hover:bg-indigo-600"
              >
                About Deep Tissue
              </Link>
            </div>
          </div>
          <div className="container text-left text-charcoal flex justify-start mr-8">
            <div className="flex flex-col tablet:flex-row items-center w-10/12 z-20">
              <div className="min-w-[250px] w-1/2 tablet:w-full rounded-xl p-4 my-4 hidden z-30 tablet:flex bg-rock/40 shadow-xl">
                <img
                  src={shaunaProfile}
                  className="h-52 tablet:h-full w-full object-cover rounded-2xl border border-solid border-4 border-rock"
                  alt="massage therapist profile"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
