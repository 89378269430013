import React from 'react';
// import GoogleMaps from './GoogleMaps';

export default function ContactUs() {
  return (
    <section className="flex justify-center items-center flex-wrap mt-32 mb-24 py-12 bg-contact bg-cover">
      <div className="w-10/12 px-4 rounded-3xl mobile:max-w-xl tablet:max-w-full laptop:max-w-screen-xl tablet:px-24 laptop:px-8 bg-white shadow-2xl">
        <div className="flex flex-col items-center justify-center w-full laptop:flex-row p-4">
          <div className="laptop:mb-0 laptop:max-w-lg">
            <div className="max-w-xl my-6 ">
              <h2 className="font-title text-5xl tablet:mt-0 font-thin tracking-tight text-charcoal tablet:text-8xl tablet:leading-none max-w-lg text-center">
                Contact:
              </h2>
              <p className="text-charcoal text-xl laptop:text-3xl py-8 text-center font-title">
                Shauna Michelle Moody LMT, NCBTMB
              </p>
              <p className="text-charcoal laptop:text-2xl text-center">
                3405 Kenyon St. Suite 307 San Diego, CA. 92110
              </p>
              <p className="text-charcoal laptop:text-2xl mt-8 text-center">
                619~261~3070
              </p>
            </div>
          </div>
          {/* <GoogleMaps /> */}
          <div className="flex justify-center items-center bg-charcoal mb-10 mt-8 w-full h-full laptop:w-2/5 laptop:ml-20">
            <iframe
              className="flex justify-center items-center w-full h-full"
              loading="lazy"
              allowfullscreen
              src="https://www.google.com/maps/embed/v1/place?q=3405%20Kenyon%20St.%20Suite%20307%20San%20Diego%2C%20CA.%2092110&key=AIzaSyAI_iOtOTqcUPOjicH5IZahe4NVwb0MpZc"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
