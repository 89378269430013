import React from 'react'
import TitlePage from '../components/TitlePage';
import AboutMassage from '../components/AboutMassage';
import Rates from '../components/Rates';
import styles from '../styles';
import ContactUs from '../components/ContactUs';
// import TestimonialsCarousel from '../components/TestimonialsCarousel';

export default function Home() {
  return (
    <>
      <div className="w-screen flex flex-col z-0 no-scrollbar overflow-y-auto bg-rock">
        <div className={`${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <TitlePage />
          </div>
        </div>
        <div className={`${styles.boxWidth}`}>
          {/* <TestimonialsCarousel /> */}
          <AboutMassage />
          <Rates />
          <ContactUs />
        </div>
      </div>
    </>
  );
}
