import React from 'react';
import { carouselTestimonials } from '../data/testimonials';
import testimonialsImg from '../images/testimonials.png';

export default function Testimonials() {
  return (
    <div className="flex flex-col bg-rock tablet:px-12">
      <div className="py-4 rounded-xl">
        <div className="py-8 text-center">
          <div className="text-5xl tablet:text-7xl text-charcoal font-title">
            Testimonials
          </div>
        </div>
        <div className="bg-white/40 rounded-xl tablet:py-12 flex flex-col tablet:px-10 tablet:flex-row items-center justify-center gap-6">
          <div className="flex justify-center flex-col items-center">
            <div className="flex justify-center">
              <img
                src={testimonialsImg}
                alt="relaxing beach"
                className="max-h-[350px] w-full"
              />
            </div>
            {carouselTestimonials.map((testimonial) => (
              <div
                className="w-full h-full flex items-center my-8 laptop:w-5/6 desktop:w-full max-w-5xl carousel rounded-box bg-[#E4D5CB] shadow-2xl"
                key={testimonial.index}
              >
                <div className="w-full py-8 px-4 desktop:w-full max-w-5xl carousel rounded-box shadow-2xl">
                  <div className="carousel w-full">
                    <div
                      id="slide1"
                      className="carousel-item relative w-full flex items-center justify-center"
                    >
                      <p className="text-charcoal text-lg italic">
                        "{testimonial.description}"
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              // </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
