import React from 'react';
import building from '../images/building.jpg';

export default function Contact() {
  return (
    <section className="flex flex-col justify-center text-center items-center pt-12">
      <h2 className="font-title text-6xl tablet:mt-0 font-thin tracking-tight text-charcoal text-5xl tablet:text-7xl tablet:leading-none max-w-lg text-center">
        Contact
      </h2>
      <div className="flex justify-center items-center flex-wrap mt-12 mb-24 py-12 bg-contact bg-cover w-full">
        <div className="w-10/12 px-4 rounded-3xl mobile:max-w-xl tablet:max-w-full laptop:max-w-screen-xl tablet:px-24 laptop:px-8 bg-white shadow-2xl">
          <div className="flex flex-col items-center justify-center w-full laptop:flex-row p-4">
            <div className="mb-16 laptop:mb-0 laptop:max-w-lg">
              <div className="max-w-xl my-6 flex flex-col justify-center items-center">
                <p className="text-charcoal text-xl laptop:text-3xl py-8 text-center font-title">
                  Shauna Michelle Moody LMT, NCBTMB
                </p>
                <p className="text-charcoal laptop:text-2xl text-center">
                  3405 Kenyon St. Suite 307 San Diego, CA. 92110
                </p>
                <p className="text-charcoal laptop:text-2xl mt-8 text-center">
                  619~261~3070
                </p>
                <p className="text-charcoal text-md laptop:text-xl mt-8 text-center italic">
                  We are centrally located in the Point Loma area of San Diego,
                  and we offer flexible hours including evenings and weekends.
                  Please call or text Shauna with any questions, or to schedule
                  your appointment.
                </p>
                <div className="w-full flex justify-center m-4 pt-2">
                  <img
                    src={building}
                    alt="massage center building"
                    className="rounded-xl w-3/4"
                  />
                </div>
                <div className="flex justify-center items-center bg-charcoal laptop:mb-10 mt-8 w-full h-full">
                  <iframe
                    className="flex justify-center items-center w-full h-full"
                    loading="lazy"
                    allowfullscreen
                    src="https://www.google.com/maps/embed/v1/place?q=3405%20Kenyon%20St.%20Suite%20307%20San%20Diego%2C%20CA.%2092110&key=AIzaSyAI_iOtOTqcUPOjicH5IZahe4NVwb0MpZc"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
