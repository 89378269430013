export const carouselTestimonials = [
  {
    index: 0,
    description:
      'I have been struggling with migraines triggered by barometric changes (very common) for years and began seeing Shauna approx. 3 months ago.  Besides being a warm, lovely person, Shauna is truly gifted.  By working on very specific areas at the base of head, neck and shoulders, Shauna has gotten these areas to quit reacting which has left me headache free for longer than I thought possible.  I wish I had found her years ago but am so grateful now!!!',
  },
  {
    index: 1,
    description:
      "I started going to Shauna for my migraines.I had been to many Dr's and had tried every medicine on the market. I tried the Botox and even had sinus surgery, but nothing worked for me. Getting massages has helped to reduce the frequency and severity of my headaches. Shauna specializes in working on the neck, so I highly recommend her for helping with neck tension. I have been going to her for 12 years now and I absolutely love her. She is a lovely person and an incredible masseuse. If you have any kind of muscle pain she can help you with it.",
  },
  {
    index: 2,
    description:
      "This review is for Shauna... I have been treated by Shauna multiple times over the past couple of years. She has done an excellent job relieving the tension I have in my neck and back through deep tissue massage. She's also very kind and genuine. Recently I had a nasty flare up, making it difficult to walk without excruciating pain. After the massage my pain was significantly less. She worked on me a few more times until I returned to a functioning level. She played a significant role in my recovery phase. Thank you Shauna for getting me back on my feet (literally)!",
  },
  {
    index: 3,
    description:
      "I used to suffer from horrible migraines - ones that would last a few days.. After working with Shauna my migraines are few and far between... And, when I do get them, they are so mild as to what they used to be... I owe this to her help. She is a miracle worker.. I can't thank her enough. She is the best and if you suffer from any pain and need some work done on your body, this is the place to come... This is not a spa but actual physical therapy",
  },
  {
    index: 4,
    description:
      "If anyone out there has migraines and are serious about getting rid of them, they need to call Shauna Moody. I had suffered from migraines for 15 years. I have been through countless neurologists and have been on every type of medicine available and yet nothing helped. I was in an urgent care or emergency room getting powerful meds to bring me out of the pain cycles at least 4 - 6 times a month. The throbbing pain, nausea, vomiting, light sensitivity, noise sensitivity, I had it all and no one could seem to help me. That is, until I met Shauna. I, like most people who have had migraines, was skeptical. I thought, 'how can a therapeutic massage help me?' But I decided to give it a chance since I was told that Shauna's specialty was migraine treatment and she had suffered from migraines earlier in her life (that is until she was cured from having migraines using the type of therapeutic massage techniques that she currently uses on her clients) and it truly changed my life! Since my very first treatment in September of 2009, I am pain free! Not only that, I no longer need any of my migraine preventative or pain controlling medicines! Imagine such an improvement in my quality of life! I want the same for anyone who is suffering from migraines. Shauna has been able to help me in a way no medical doctor has been able to do! I am not paid to submit my review. I am just a grateful client of Shauna's.",
  },
  {
    index: 5,
    description:
      'I started coming to the Health and Vision Acupuncture and Massage Center after having surgery on my rotator cuff for a dislocation. I was in excruciating pain from scar tissue and the surrounding tightness in my muscles. I have been receiving acupuncture and deep tissue massage together on a regular basis for the past five months, and no longer have the pain in my shoulder.  I no longer take the pain medication (which before, was necessary just to get through the day), and my range of motion has improved so much it has allowed me to go back to exercising regularly. The acupuncture and massage has made such a huge impact in my life, and I am grateful to have found this office. They are excellent therapists who actually take the time to get to know you and what your ailments are. I am happy to write a testimonial in hopes that others can find relief by coming here as well. I have since sent my two daughters and my wife in for regular massages since I now know the benefits of receiving them.',
  },
  {
    index: 6,
    description:
      'I surf, swim, and weight train 3 to 4 days per week, and have benefited so much from deep tissue massage. It has helped my flexibility and range of motion, and loosened my muscles. I have also had chronic back spasms for years, and no longer get them after working out since getting the deep tissue massages.',
  },
  {
    index: 7,
    description:
      "My husband and I got massages from Shauna, and she is absolutely amazing! Very passionate and caring massage therapist. Not only is she friendly, but her work is incredible. After our massages, both my husband and I were so relaxed and felt great! You can tell she knows what she's doing and that gave us such an awesome experience. Can't wait to go back! Definitely one of the best in town! Thanks Shauna!",
  },
  {
    index: 8,
    description:
      "I've been working w/ Shauna for several years. She is an outstanding massage therapist, the best I've ever known. She has expert knowledge of anatomy & muscles & gives the best deep tissue massage ever, w/particular focus on head, neck & shoulders. On top of her professionalism & expertise, she is warm, friendly & joyful. I can't recommend her work highly enough.",
  },
  {
    index: 9,
    description:
      'I just started going to Shauna recently for massages and wish I had started going years ago. I usually have a lot of neck and back pain from getting whiplash in a car accident and she is always able to relieve the pain and make me feel so much better. Shauna is one of the sweetest and kindest people I have ever met! I definitely recommend her to anyone who is looking to get an amazing massage. I am so happy I finally found someone that I can continue to go to on a regular basis for years to come!',
  },
  {
    index: 10,
    description:
      'Just wanted to let anyone interested know that Shauna is a wonderful masseuse that has helped me on many occasion as well as my wife who suffers particularly with migraines.....one of her specialties!!!! The location is convenient and nice and the service and price are well worth coming here. My wife and I have never been disappointed in the treatment that we have received. The office is quaint and welcoming. And as soon as you walk through the door the quiet and relaxing atmosphere starts destressing you right away. In short, we LOVE it !!! :-)',
  },
  {
    index: 11,
    description:
      'I just started coming to the Health and Vision Acupuncture and Massage Center about two months ago.  I like to get massage about once a month, and I really like DEEP tissue massage.  Unfortunately most places that say they do deep tissue massage, do not.  Here, I have found that they are very skilled in deep tissue bodywork.  They are very nice, and do not rush like some places do.  I can feel the difference in my muscles, and I will continue to come here for massage. Thank you.',
  },
  {
    index: 12,
    description:
      "For those of you with migraines... I highly recommend you book an appointment for a massage with Shauna Moody... She is amazing. Even if you don't have migraines! Not only is she great at what she does but she is the sweetest, kindest soul. I highly recommend her.",
  },
  {
    index: 13,
    description:
      'I have been coming to Health and Vision Acupuncture and Massage Center for almost three years where I have been receiving deep tissue massage for my migraine headaches. I went from having very frequent and intense migraines, to now rarely having any headache at all, and feeling incredible relief from the tightness in my neck and shoulders. It is amazing since I had been on medication for over four years, and I have not needed to take anything (medication) since I started getting these massages. I am very happy to have found this center and my therapist, Shauna Moody. I would refer anyone who suffers from migraines or neck and shoulder pain.',
  },
  {
    index: 14,
    description:
      'Shauna is the nicest and most caring massage therapist I have ever seen and I will never go anywhere else. She is amazing and has essentially gotten rid of my migraines. I have tried every medication and even Botox but nothing worked until I saw her. Although I was skeptical and I read how all the other therapies I tried worked wonders for other people, this is the only therapy that has helped me and is completely holistic. You will NOT find a better massage therapist and nicer person anywhere.',
  },
  {
    index: 15,
    description:
      "I had bad migraine headaches every day for 40 years when I first came to see Shauna Moody. The level of pain was 8 1/2 (out of 10) every day, all day. After seeing Shauna twice, the pain level went down to 4 1/2 and has remained low. Shauna has been healing me for approximately 15 years. I also no longer get headaches every day. I'd highly recommend her to anyone suffering from migraines.",
  },
];
