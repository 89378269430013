import React from 'react';
import healing from '../images/healing.jpg';

export default function Migraines() {
  return (
    <div className="flex flex-col bg-rock tablet:px-12">
      <div className="py-4 rounded-xl">
        <div className="py-8 text-center">
          <div className="text-5xl tablet:text-7xl text-charcoal font-title">
            Migraine headaches
          </div>
          <div className="lead-xl font-light text-2xl mt-8 font-sans">
            Information about migraines
          </div>
        </div>
      </div>
      <div className="bg-white/40 rounded-xl tablet:py-12 flex flex-col tablet:px-10 tablet:flex-row items-center justify-center gap-6">
        <div>
          <img
            src={healing}
            alt="healing dandelion"
            className="rounded-t-xl w-full"
          />
          <div className="pl-4 pr-2 tablet:px-12 pt-10 pb-14 bg-sand rounded-lg shadow-2xl">
            <div className="text-charcoal space-y-4 flex flex-col items-center px-2">
              <div className="text-lg font-light">
                <p className="my-4">
                  Migraine headaches are one of the most common ailments
                  effecting people today. They are also one of the most
                  misunderstood, misdiagnosed, and mistreated.
                </p>
                <p className="my-4">
                  We have seen clients who have tried everything to help them
                  with their migraine symptoms including medications, surgery,
                  injections, neurological therapies, hormone treatments,
                  experimental drugs and many more, sometimes very invasive,
                  procedures which provide temporary relief if any.
                </p>
                <p className="my-4">
                  Migraine headache symptoms range from a slight throbbing in
                  the head, to extreme pain, nausea, vomiting, dizziness, light
                  and noise sensitivity, excessive pressure in the head,
                  inability to talk, think, or perform simple activities, and so
                  on... They are difficult to understand and evaluate for
                  someone who has never experienced them, which could explain
                  the lack of knowledge and treatments available. Migraines are
                  sometimes called cluster headaches, tension headaches, sinus
                  or pressure headaches, ocular migraines, hormonal or
                  premenstrual headaches, and neurological headaches depending
                  on which symptoms are more pronounced. However to us, these
                  all constitute a migraine headache, and can be treated with
                  this type of massage.
                </p>
                <p className="my-4">
                  Shauna Moody, having suffered from migraines for many years,
                  has been specializing in the treatment of migraine headaches
                  for the past 12 years through deep tissue massage. She
                  discovered this technique from a massage therapist she was
                  seeing at the time, and noticed her headaches becoming less
                  and less frequent and eventually ceasing all together. She has
                  since been dedicated to helping people who suffer from these
                  headaches using the same therapeutic massage that she
                  received, and drawing the same results that she experienced.
                </p>
                <p className="my-4">
                  The massage treatment for migraine headaches consists of
                  mostly very deep work in the neck, along with relaxation and
                  preventative techniques. Results vary from person to person as
                  some people have reported not having any migraines after their
                  first session, and some notice a gradual drop in frequency,
                  duration and intensity.{' '}
                  <span className="font-bold">Every person</span> Shauna has
                  treated for migraine headaches, regardless of their history
                  and prior treatments, has found relief from this therapy. We
                  feel very confident in this treatment as we have seen life
                  changing results. Our clients have been able to avoid invasive
                  surgeries and stop taking their medications.
                </p>
                <p className="my-4">
                  Please contact Shauna anytime to discuss any questions you
                  have about migraine therapy or to schedule an appointment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
